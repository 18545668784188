import React from "react"
// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"

class Tags extends React.Component {
  render() {
    const { pageContext, data } = this.props;
    const siteTitle = this.props.data.site.siteMetadata.title
    const { tag } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark
    const tagHeader = `${totalCount} article${
      totalCount === 1 ? "" : "s"
    } dans la catégorie "${tag}"`


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={tag}
          description={tagHeader}
        />
        <h1>{tagHeader}</h1>
          {edges.map(({ node }) => {
            // const { slug } = node.fields
            const title = node.frontmatter.title
            const tags = node.frontmatter.tags
            return (
              // <li key={slug}>
              //   <Link to={slug}>{title}</Link>
              // </li>
              <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: "0.4375rem",
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <div className="metadata">
              {node.frontmatter.date}

              <ul className="is-unstyled tags-list">
                {tags.map(tag => (
                  <li className="tags-list_item" key={kebabCase(tag)}>
                    <Link className="tags-list_link" to={`/tags/${kebabCase(tag)}/`}>
                      {tag}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
            )
          })}
        <Link className="mtm is-inline-block" to="/tags">← Voir toutes les catégories</Link>
      </Layout>
    )
  }
}
export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(
              formatString: "DD MMM YYYY"
              locale: "fr-FR"
            )
            title
            description
            tags
          }
        }
      }
    }
  }
`